@import url("https://fonts.cdnfonts.com/css/inter");
:root {
    --primary-color: #434e89;
    --second-color: #d8d206;
    --white-color: #fff;
    --black-color: #000;
    --text-color: #333;
    --border-color: #dbdbdb;
    --header-height: 120px;
}
* {
    font-family: "Inter", sans-serif;
}
html {
    scroll-behavior: smooth;
}
body {
    background-color: var(--primary-color);
}
.bg-container {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
}
.bg {
    flex: 1;
    background-repeat: no-repeat;
    background-size: cover;
    background-size: cover;
}
.bg1 {
    background-image: url("../assets/images/2.png");
    background-position: left;
}
.bg2 {
    display: none;
    background-image: url("../assets/images/1.jpg");
    background-position: top;
}
.bg3 {
    background-image: url("../assets/images/4.png");
    background-position: center;
}
@media screen and (min-width: 970px) {
    .bg2 {
        display: flex;
    }
}

.btn-animation {
    animation: btnanimation 0.5s infinite alternate;
}

@keyframes btnanimation {
    from {
        opacity: 0.8;
        transform: scale(0.95);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}
